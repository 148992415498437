import React, {useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import {Link} from "react-router-dom";
import {AiOutlineHome} from "react-icons/ai";
import {CgFileDocument} from "react-icons/cg";

function NavBar() {
    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);
    const [showText, setShowText] = useState(false);

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        } else {
            updateNavbar(false);
        }
    }

    window.addEventListener("scroll", scrollHandler);
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <Navbar expanded={expand}
            fixed="top"
            expand="md"
            className={
                navColour ? "sticky" : "navbar"
        }>
            <Container>
                <Navbar.Brand href="/" className="d-flex">
                    <div className={
                        `brand-text ${
                            showText ? "show" : "hide"
                        }`
                    }>
                        The Gym Community
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"
                    onClick={
                        () => {
                            updateExpanded(expand ? false : "expanded");
                        }
                }>
                    <span></span>
                    <span></span>
                    <span></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto" defaultActiveKey="#home">
                        <Nav.Item>
                            <Nav.Link as={Link}
                                to="/"
                                onClick={
                                    () => updateExpanded(false)
                            }>
                                <AiOutlineHome style={
                                    {marginBottom: "2px"}
                                }/>
                                Home
                            </Nav.Link>
                        </Nav.Item>

                        <Dropdown as={Nav.Item}>
  <Dropdown.Toggle as={Nav.Link}>
    <CgFileDocument style={{ marginBottom: "2px" }} /> Resources
  </Dropdown.Toggle>
  <Dropdown.Menu>
    {/* General Education Section */}
    <Dropdown as="div" drop="end">
      <Dropdown.Toggle className="dropdown-item" style={{ backgroundColor: "transparent", border: "none" }}>
        General Education
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/library/content/genEd/readLabel">
          Reading a Nutritional Label
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/library/content/genEd/StayHydro">
          The Essentials of Staying Hydrated
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/library/content/genEd/TrainingTerms">
          Key Training Terms Explained
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/library/content/genEd/RunningLifting">
          Running and Lifting
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

    {/* Mobility Section */}
    <Dropdown as="div" drop="end">
      <Dropdown.Toggle className="dropdown-item" style={{ backgroundColor: "transparent", border: "none" }}>
        Mobility
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/library/content/mobility/FlexibilityRoutines">
          Flexibility Routines
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/library/content/mobility/MobilityRoutines">
          Mobility Routines
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/library/content/mobility/SelfMyoRelease">
          Self Myofascial Release
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

    {/* Hypertrophy Section */}
    <Dropdown as="div" drop="end">
      <Dropdown.Toggle className="dropdown-item" style={{ backgroundColor: "transparent", border: "none" }}>
        Hypertrophy
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/library/content/hypertro/Hypertrophy101">
          Hypertrophy 101
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/library/content/hypertro/PrinciplesOfHyper">
          Principles of Hypertrophy Training
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

    {/* Diet Section */}
    <Dropdown as="div" drop="end">
      <Dropdown.Toggle className="dropdown-item" style={{ backgroundColor: "transparent", border: "none" }}>
        Diet
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/library/content/diet/bulkingvscutting">
          Bulking Vs. Cutting
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/library/content/diet/mealsizeandtiming">
          Meal Size and Timing
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

    {/* Strength Section */}
    <Dropdown as="div" drop="end">
      <Dropdown.Toggle className="dropdown-item" style={{ backgroundColor: "transparent", border: "none" }}>
        Strength
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/library/content/Streng/Strength101">
          Strength 101
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/library/content/Streng/StrengthOverload">
          Overload Requirement For Strength
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </Dropdown.Menu>
</Dropdown>


                        <Dropdown as={
                            Nav.Item
                        }>
                            <Dropdown.Toggle as={
                                Nav.Link
                            }>
                                <CgFileDocument style={
                                    {marginBottom: "2px"}
                                }/>
                                Exercise Library
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link}
                                    to="/">
                                    Coming Soon!
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown as={
                            Nav.Item
                        }>
                            <Dropdown.Toggle as={
                                Nav.Link
                            }>
                                <CgFileDocument style={
                                    {marginBottom: "2px"}
                                }/>
                                External Links
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={
                                    () => openInNewTab("https://www.discord.gg/gymcommunity")
                                }>
                                    Discord
                                </Dropdown.Item>
                                <Dropdown.Item onClick={
                                    () => openInNewTab("https://www.patreon.com/c/thegymcommunity")
                                }>
                                    Patreon
                                </Dropdown.Item>
                                <Dropdown.Item onClick={
                                    () => openInNewTab("https://www.youtube.com/@TheGymCommunitygg")
                                }>
                                    Youtube
                                </Dropdown.Item>
                                <Dropdown.Item onClick={
                                    () => openInNewTab("https://medium.com/@ogbroly")
                                }>
                                    Blog
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Nav.Item>
                            <Nav.Link as={Link}
                                to="#"
                                onClick={
                                    () => openInNewTab("https://www.egreen.training")
                            }>
                                <CgFileDocument style={
                                    {marginBottom: "2px"}
                                }/>
                                Paid Training
                            </Nav.Link>
                        </Nav.Item>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;
