import React, { useState } from "react";
import { Container, Offcanvas, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import Particle from "../../../Particle";
import Break from "../../Break";

const ReadLabel = () => {
  const [show, setShow] = useState(false);

  return (
    <Container fluid className="about-section py-5">
      {/* Page Title */}
      <Break></Break>
      <Container className="text-center mb-4">
        <h1 className="mb-3" style={{ fontSize: "2.5rem", fontWeight: "bold" }}>
          Understanding Nutrition Labels
        </h1>
        <p style={{ fontSize: "1.2rem", color: "#555" }}>
          Learn how to read and understand the various components of nutrition labels.
        </p>
      </Container>

      {/* Offcanvas for Table of Contents */}
      <Offcanvas show={show} onHide={() => setShow(false)} placement="end" style={{ maxWidth: "300px" }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Contents</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="list-unstyled">
            <li className="mb-3">
              <a href="#0" style={{ textDecoration: "none", color: "#007bff" }}>
                The Basics
              </a>
            </li>
            <li className="mb-3">
              <a href="#1" style={{ textDecoration: "none", color: "#007bff" }}>
                Macronutrients
              </a>
            </li>
            <li className="mb-3">
              <a href="#2" style={{ textDecoration: "none", color: "#007bff" }}>
                Micronutrients
              </a>
            </li>
            <li className="mb-3">
              <a href="#3" style={{ textDecoration: "none", color: "#007bff" }}>
                Percent Daily Values (%DV)
              </a>
            </li>
            <li className="mb-3">
              <a href="#4" style={{ textDecoration: "none", color: "#007bff" }}>
                Additional Information
              </a>
            </li>
            <li className="mb-3">
              <a href="#5" style={{ textDecoration: "none", color: "#007bff" }}>
                Practical Tips
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Content Sections */}
      <Container>
        <Section id="0" title="The Basics">
          <p>
            <strong>Serving Size and Servings Per Container:</strong> Look for the serving size and number of servings per container. This helps you interpret the rest of the label.
          </p>
          <p>
            <strong>Calories:</strong> Understand how much energy you get from one serving of food. Pay attention if you’re watching your weight.
          </p>
        </Section>

        <Section id="1" title="Macronutrients">
          <p><strong>Fats:</strong></p>
          <ul>
            <li><strong>Total Fat:</strong> The sum of all fats.</li>
            <li><strong>Saturated Fat:</strong> Linked to heart disease.</li>
            <li><strong>Trans Fat:</strong> Unhealthy fat to avoid.</li>
          </ul>
          <p><strong>Protein:</strong> Essential for muscle repair and growth.</p>
          <p><strong>Carbohydrates:</strong></p>
          <ul>
            <li><strong>Total Carbohydrates:</strong> Includes all carbs, both good and bad.</li>
            <li><strong>Dietary Fiber:</strong> Good for digestion.</li>
            <li><strong>Sugars:</strong> Includes natural and added sugars.</li>
          </ul>
        </Section>

        <Section id="2" title="Micronutrients">
          <p>
            <strong>Vitamins and Minerals:</strong> Found at the bottom of the label. Essential for various functions but needed in smaller amounts.
          </p>
        </Section>

        <Section id="3" title="Percent Daily Values (%DV)">
          <p>
            <strong>The %DV:</strong> Helps you understand the percentage of each nutrient in one serving.
          </p>
          <p><strong>Guide:</strong> 5% DV or less is low; 20% DV or more is high.</p>
        </Section>

        <Section id="4" title="Additional Information">
          <p><strong>Ingredients List:</strong> Ingredients are listed by weight. Look for items you may want to avoid, like sugar or salt.</p>
          <p><strong>Allergens:</strong> Check for common allergens if you have food allergies.</p>
        </Section>

        <Section id="5" title="Practical Tips">
          <ul>
            <li><strong>Compare and Contrast:</strong> Compare labels of similar products to make informed choices.</li>
            <li><strong>Beware of Marketing Terms:</strong> Words like "natural" or "organic" don't always mean healthier.</li>
          </ul>
        </Section>
      </Container>

      <Particle />
    </Container>
  );
};

const Section = ({ id, title, children }) => (
  <Row id={id} className="mb-5">
    <Col>
      <h2 className="mb-3" style={{ fontSize: "1.8rem", fontWeight: "600" }}>
        {title}
      </h2>
      {children}
    </Col>
  </Row>
);

export default ReadLabel;

