import React, { useState, useEffect } from "react";
import Footer from "./components/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";

import Home from "./components/Home/Home";
import Library from "./components/Library/Library";
import ExercisePlanner from "./components/ExercisePlanner/ExercisePlanner";

// Gen Ed Subsections
import ReadLabel from "./components/Library/Content/GenEd/ReadLabel";
import StayHydro from "./components/Library/Content/GenEd/StayHydro";
import TrainingTerms from "./components/Library/Content/GenEd/TrainingTerms";
import RunningLifting from "./components/Library/Content/GenEd/RunningLifting";
import RecoveryTime from "./components/Library/Content/GenEd/RecoveryTime";
import RepRangeMus from "./components/Library/Content/GenEd/RepRangeMus";
import SleepRec from "./components/Library/Content/GenEd/SleepRec";
import EquipmentRec from "./components/Library/Content/GenEd/EquipmentRec";
import IncreaseAthleticism from "./components/Library/Content/GenEd/IncreaseAthleticism";
import TrainingUniversals from "./components/Library/Content/GenEd/TrainingUniversals";
import TrainingFailure from "./components/Library/Content/GenEd/TrainingFailure";
import ProperForm from "./components/Library/Content/GenEd/ProperForm";
import BuildBench from "./components/Library/Content/GenEd/BuildBench";
import BuildSquat from "./components/Library/Content/GenEd/BuildSquat";
import BuildDeadLift from "./components/Library/Content/GenEd/BuildDeadLift";

// Mobility Subsections
import FlexibilityRoutines from "./components/Library/Content/Mobility/FlexibilityRoutines";
import MobilityRoutines from "./components/Library/Content/Mobility/MobilityRoutines";
import SelfMyoRelease from "./components/Library/Content/Mobility/SelfMyoRelease";
import ElbowWristPain from "./components/Library/Content/Mobility/ElbowWristPain";
import TennisElbow from "./components/Library/Content/Mobility/TennisElbow";
import Flexibility from "./components/Library/Content/Mobility/Flexibility";
import Stretching from "./components/Library/Content/Mobility/Stretching";
import Yoga from "./components/Library/Content/Mobility/Yoga";

// Hypertro Subsections
import Hypertrophy101 from "./components/Library/Content/Hypertro/Hypertrophy101";
import PrinciplesOfHyper from "./components/Library/Content/Hypertro/PrinciplesOfHyper";
import HypertrophyProgramming from "./components/Library/Content/Hypertro/HypertrophyProgramming";

// Streng Subsections
import StrengthOverload from "./components/Library/Content/Streng/StrengthOverload";
import Strength101 from "./components/Library/Content/Streng/Strength101";
import SevournTips from "./components/Library/Content/Streng/SevournTips";
import SBDVariations from "./components/Library/Content/Streng/SBDVariations";
import DifferentPowerliftingTechniques from "./components/Library/Content/Streng/DifferentPowerliftingTechniques";

// Diet Subsections
import BulkingvsCutting from "./components/Library/Content/Diet/BulkingvsCutting";
import Cookbooks from "./components/Library/Content/Diet/Cookbooks";
import CalculatingNutritionalValues from "./components/Library/Content/Diet/CalculatingNutritionalValues";
import Carbs from "./components/Library/Content/Diet/Carbs";
import Diet101 from "./components/Library/Content/Diet/Diet101";
import DietResources from "./components/Library/Content/Diet/DietResources";
import Fats from "./components/Library/Content/Diet/Fats";
import Protein from "./components/Library/Content/Diet/Protein";
import Supplementation from "./components/Library/Content/Diet/Supplementation";
import TeenDieting from "./components/Library/Content/Diet/TeenDieting";
import TrackingWeight from "./components/Library/Content/Diet/TrackingWeight";
import MealSizeandTiming from "./components/Library/Content/Diet/MealSizeandTiming";

// Injury Subsections
import NutritionHydration from "./components/Library/Content/Injury/NutritionHydration";
import BalanceWorkoutMuscle from "./components/Library/Content/Injury/BalanceWorkoutMuscle";
import WarmUpCoolDown from "./components/Library/Content/Injury/WarmUpCoolDown";
import Resting from "./components/Library/Content/Injury/Resting";
import MobilityFlexibility from "./components/Library/Content/Injury/MobilityFlexibility";
import ProgressionAndPeriodization from "./components/Library/Content/Injury/ProgressionAndPeriodization";
import ProperFormAndTechnique from "./components/Library/Content/Injury/ProperFormAndTechnique";

// Programs Subsections
import Strength from "./components/Library/Content/Programs/Strength";
import Hypertrophy from "./components/Library/Content/Programs/Hypertrophy";
import Calisthenics from "./components/Library/Content/Programs/Calisthenics";
import AtHome from "./components/Library/Content/Programs/Athome";
import Specializations from "./components/Library/Content/Programs/Specializations";

//Mindfulness Subsections
import CommunityCreatedBooklist from "./components/Library/Content/Mindfulness/CommunityCreatedBooklist";
import ControllingYourEmotions from "./components/Library/Content/Mindfulness/ControllingYourEmotions";
import Discipline from "./components/Library/Content/Mindfulness/Discipline";
import HowToLoseGracefully from "./components/Library/Content/Mindfulness/HowToLoseGracefully";
import LearningToPlanMoreEfficiently from "./components/Library/Content/Mindfulness/LearningToPlanMoreEfficiently";
import Mindfulness101 from "./components/Library/Content/Mindfulness/Mindfulness101";
import OvercomingProcrastination from "./components/Library/Content/Mindfulness/OvercomingProcrastination";
import Resources from "./components/Library/Content/Mindfulness/Resources";
import WhoAmI from "./components/Library/Content/Mindfulness/WhoAmI";
import Willpower from "./components/Library/Content/Mindfulness/Willpower";

// import ChatBot from "./components/Chatbot/chatbot";
import Applications from "./components/Applications/Applications";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/library" element={<Navigate to="/" />} />
          <Route path="/exerciseplanner" element={<Navigate to="/" />} />
          <Route path="/applications" element={<Navigate to="/" />} />

          {/*GenEd Paths*/}
          <Route path="/library/content/genEd/readLabel" element={<ReadLabel />} />
          <Route path="/library/content/genEd/stayHydro" element={<StayHydro />} />
          <Route path="/library/content/genEd/TrainingTerms" element={<TrainingTerms />} />
          <Route path="/library/content/genEd/RunningLifting" element={<RunningLifting />} />
          <Route path="/library/content/genEd/RecoveryTime" element={<RecoveryTime />} />
          <Route path="/library/content/genEd/RepRangeMus" element={<RepRangeMus />} />
          <Route path="/library/content/genEd/SleepRec" element={<SleepRec />} />
          <Route path="/library/content/genEd/EquipmentRec" element={<EquipmentRec />} />
          <Route path="/library/content/genEd/IncreaseAthleticism" element={<IncreaseAthleticism />} />
          <Route path="/library/content/genEd/TrainingUniversals" element={<TrainingUniversals />} />
          <Route path="/library/content/genEd/TrainingFailure" element={<TrainingFailure />} />
          <Route path="/library/content/genEd/ProperForm" element={<ProperForm />} />
          <Route path="/library/content/genEd/BuildBench" element={<BuildBench />} />
          <Route path="/library/content/genEd/BuildSquat" element={<BuildSquat />} />
          <Route path="/library/content/genEd/BuildDeadLift" element={<BuildDeadLift />} />

          {/* Mobility paths */}
          <Route path="/library/content/mobility/FlexibilityRoutines" element={<FlexibilityRoutines />} />
          <Route path="/library/content/mobility/MobilityRoutines" element={<MobilityRoutines />} />
          <Route path="/library/content/mobility/selfMyoRelease" element={<SelfMyoRelease />} />
          <Route path="/library/content/mobility/elbowWristPain" element={<ElbowWristPain />} />
          <Route path="/library/content/mobility/tennisElbow" element={<TennisElbow />} />
          <Route path="/library/content/mobility/Flexibility" element={<Flexibility />} />
          <Route path="/library/content/mobility/Stretching" element={<Stretching />} />
          <Route path="/library/content/mobility/Yoga" element={<Yoga />} />

          {/* Hypertrophy paths */}
          <Route path="/library/content/Hypertro/Hypertrophy101" element={<Hypertrophy101 />} />
          <Route path="/library/content/Hypertro/PrinciplesOfHyper" element={<PrinciplesOfHyper />} />
          <Route path="/library/content/Hypertro/HypertrophyProgramming" element={<HypertrophyProgramming />} />

          {/* Strength paths */}
          <Route path="/library/content/Streng/Strength101" element={<Strength101 />} />
          <Route path="/library/content/Streng/SevournTips" element={<SevournTips />} />
          <Route path="/library/content/Streng/SBDVariations" element={<SBDVariations />} />
          <Route path="/library/content/Streng/DifferentPowerliftingTechniques" element={<DifferentPowerliftingTechniques />} />
          <Route path="/library/content/Streng/StrengthOverload" element={<StrengthOverload />} />

          {/* Diet Paths */}
          <Route path="/library/content/diet/bulkingvscutting" element={<BulkingvsCutting />} />
          <Route path="/library/content/diet/mealsizeandtiming" element={<MealSizeandTiming />} />
          <Route path="/library/content/diet/calculatingnutritionalvalues" element={<CalculatingNutritionalValues />} />
          <Route path="/library/content/diet/carbs" element={<Carbs />} />
          <Route path="/library/content/diet/cookbooks" element={<Cookbooks />} />
          <Route path="/library/content/diet/diet101" element={<Diet101 />} />
          <Route path="/library/content/diet/dietresources" element={<DietResources />} />
          <Route path="/library/content/diet/fats" element={<Fats />} />
          <Route path="/library/content/diet/protein" element={<Protein />} />
          <Route path="/library/content/diet/supplementation" element={<Supplementation />} />
          <Route path="/library/content/diet/teendieting" element={<TeenDieting />} />
          <Route path="/library/content/diet/trackingweight" element={<TrackingWeight />} />

          {/* Injury Paths */}
          <Route path="/library/content/injury/nutritionHydration" element={<NutritionHydration />} />
          <Route path="/library/content/injury/balanceWorkoutMuscle" element={<BalanceWorkoutMuscle />} />
          <Route path="/library/content/injury/warmUpCoolDown" element={<WarmUpCoolDown />} />
          <Route path="/library/content/injury/resting" element={<Resting />} />
          <Route path="/library/content/injury/mobilityflexibility" element={<MobilityFlexibility />} />
          <Route path="/library/content/injury/progressionandperiodization" element={<ProgressionAndPeriodization />} />
          <Route path="/library/content/injury/properFormAndTechnique" element={<ProperFormAndTechnique />} />

          {/* Programs Paths */}
          <Route path="/library/content/programs/strength" element={<Strength />} />
          <Route path="/library/content/programs/hypertrophy" element={<Hypertrophy />} />
          <Route path="/library/content/programs/calisthenics" element={<Calisthenics />} />
          <Route path="/library/content/programs/atHome" element={<AtHome />} />
          <Route path="/library/content/programs/specializations" element={<Specializations />} />

          {/* Mindfulness paths */}
          <Route path="/library/content/Mindfulness/CommunityCreatedBooklist" element={<CommunityCreatedBooklist />} />
          <Route path="/library/content/Mindfulness/ControllingYourEmotions" element={<ControllingYourEmotions />} />
          <Route path="/library/content/Mindfulness/Discipline" element={<Discipline />} />
          <Route path="/library/content/Mindfulness/HowToLoseGracefully" element={<HowToLoseGracefully />} />
          <Route path="/library/content/Mindfulness/LearningToPlanMoreEfficiently" element={<LearningToPlanMoreEfficiently />} />
          <Route path="/library/content/Mindfulness/Mindfulness101" element={<Mindfulness101 />} />
          <Route path="/library/content/Mindfulness/OvercomingProcrastination" element={<OvercomingProcrastination />} />
          <Route path="/library/content/Mindfulness/Resources" element={<Resources />} />
          <Route path="/library/content/Mindfulness/WhoAmI" element={<WhoAmI />} />
          <Route path="/library/content/Mindfulness/Willpower" element={<Willpower />} />



          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </div>
      <ScrollToTop />
    </Router>
  );
}

export default App;
